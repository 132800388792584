import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import { CompanyTile } from "../../pages/portfolio"
import Button from "../Button"

const HomePortfolio = ({ data }) => {
  const [leftColumnCompanies, setLeftColumnCompanies] = useState([])
  const [rightColumnCompanies, setRightColumnCompanies] = useState([])

  const companyTileClasses = `w-165px h-165px
    sm:w-192px sm:h-192px 
    md:w-165px md:h-165px 
    lg:w-192px lg:h-192px`

  const setLeftRightCompanies = () => {
    const left = []
    const right = []

    data.companyProfiles.forEach((company, index) => {
      const isEven = index % 2 == 0

      isEven ? left.push(company) : right.push(company)
    })

    setLeftColumnCompanies(left)
    setRightColumnCompanies(right)
  }

  useEffect(() => {
    setLeftRightCompanies()
  }, [])

  return (
    <>
      <div className="bg-mvp-grey" id="home-portfolio">
        <div
          className="container py-20
            md:py-12
            lg:pt-12 lg:pb-20
        "
        >
          <div className="flex flex-col md:flex-row md:items-center">
            <div
              className="order-2 md:order-1 grid grid-cols-2 gap-x-4 justify-items-center flex-shrink-0 items-start
            lg:gap-x-10
          "
            >
              <div
                className="grid grid-cols-1 gap-y-4
                lg:gap-y-10"
              >
                {leftColumnCompanies.map((company) => {
                  const hasTeamPicture = !!company.teamPicture
                  const teamPicture = hasTeamPicture
                    ? company.teamPicture.formats.small.url
                    : null
                  return (
                    <CompanyTile
                      link={company.url}
                      teamPhoto={teamPicture}
                      logo={
                        company.logo.formats && company.logo.formats.small
                          ? company.logo.formats.small.url
                          : company.logo.url
                      }
                      className={companyTileClasses}
                    />
                  )
                })}
              </div>
              <div className="order-1 grid grid-cols-1 gap-y-4 lg:gap-y-10 md:mt-14">
                {rightColumnCompanies.map((company) => {
                  const hasTeamPicture = !!company.teamPicture
                  const teamPicture = hasTeamPicture
                    ? company.teamPicture.formats.small.url
                    : null
                  return (
                    <CompanyTile
                      link={company.url}
                      teamPhoto={teamPicture}
                      logo={
                        company.logo.formats && company.logo.formats.small
                          ? company.logo.formats.small.url
                          : company.logo.url
                      }
                      className={companyTileClasses}
                    />
                  )
                })}
              </div>
            </div>

            <div
              className="order-1 
                md:order-2 md:pl-10
                lg:pl-24
            "
            >
              <h1
                className="font-light text-36px leading-tight tracking-tight pb-6
                lg:text-5xl
                xl:text-80px"
              >
                {data.portfolioTitle}
              </h1>
              <div
                className="tracking-wide 
                lg:text-xl
                xl:text-2xl"
              >
                {data.portfolioDescriptionLabel}
              </div>

              <div
                className="flex justify-center md:justify-start pt-6 pb-10
                md:pb-0
                xl:pt-10
              "
              >
                <Button label={data.portfolioButtonLabel} page="/portfolio" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default HomePortfolio
