import React from "react"

const Divider = ({ colour = "light", className }) => {
  const colourClass =
    colour === "dark" ? "border-mvp-dark-blue" : "border-mvp-light-blue"

  return <div className={`border-t-2 ${colourClass} ${className}`} />
}

export default Divider
