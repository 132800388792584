import { graphql } from "gatsby"
import React, { useEffect, useState, useContext } from "react"
import ReactPlayer from "react-player"
import Modal from "react-responsive-modal"

import HomeCompanyInfo from "../components/Home/HomeCompanyInfo"
import HomeCodeOfConduct from "../components/Home/HomeConduct"
import HomeFounders from "../components/Home/HomeFounders"
import HomePodcastShow from "../components/Home/HomePodcastShow"
import HomePortfolio from "../components/Home/HomePortfolio"
import HomeTeam from "../components/Home/Team/HomeTeam"
import HomeVideo from "../components/Home/HomeVideo"
import { GlobalDataContext } from "../layouts"
import { MetaTagsContext } from "../layouts"

const IndexPage = ({ data }) => {
  const globalData = React.useContext(GlobalDataContext)
  const cmsData = data.strapiHome.data
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false)
  const [videoModalId, setVideoModalId] = useState(null)
  const { setMetaTags } = useContext(MetaTagsContext)

  setMetaTags(cmsData.seo)

  const openVideoModel = (videoId) => {
    setVideoModalId(videoId)
  }

  const closeVideoModal = () => {
    setIsVideoModalOpen(false)
    setVideoModalId(null)
  }

  useEffect(() => {
    if (videoModalId) {
      setIsVideoModalOpen(true)
    }
  }, [videoModalId])

  return (
    <div>
      {cmsData && (
        <>
          <HomeVideo data={cmsData} />
          <HomePortfolio data={cmsData} />
          <HomeFounders
            data={cmsData}
            handleVideoButtonClick={openVideoModel}
          />
          {globalData && globalData.isPodcastEnabled && (
            <HomePodcastShow data={cmsData} />
          )}
          <HomeCompanyInfo data={cmsData} />
          <HomeTeam data={cmsData} />
          <HomeCodeOfConduct data={cmsData} />

          <Modal
            center
            open={isVideoModalOpen}
            onClose={closeVideoModal}
            animationDuration={100}
            styles={{
              modal: {
                width: "100%",
                maxWidth: "none",
                margin: 0,
                padding: 0,
                background: "transparent",
              },
              overlay: {
                background: "rgba(0, 0, 0, 0.5)",
              },
              closeButton: {
                fill: "white",
              },
            }}
          >
            <ReactPlayer
              url={`https://vimeo.com/${videoModalId}?title=0&byline=0&portrait=0&autoplay=1`}
              controls
              playing={true}
              width="100%"
              height="100vh"
            />
          </Modal>
        </>
      )}
    </div>
  )
}

export const query = graphql`
  {
    strapiHome {
      data {
        backgroundVideo {
          url
        }
        backgroundVideoMobile {
          url
        }
        videoLineOneWhite
        videoLineOneBlue
        videoLineTwoWhite
        videoLineTwoBlue
        videoPlayButtonLabel
        portfolioTitle
        portfolioButtonLabel
        watchVideoButtonLabel
        pmfShowTitle
        pmfShowDescription
        pmfShowButtonLabel
        teamTitle
        teamDescription
        teamButtonLabel
        conductTitle
        conductQuote
        conductButtonLabel
        conduct10Donts
        conductQuoteAuthor
        videoPlayButtonVimeoId
        portfolioDescriptionLabel
        seo {
          description
          title
        }
        conductIllustration {
          url
        }
        conductBackground {
          url
        }
        teamMembers {
          bio
          linkedInId
          homePageDisplayOrder
          name
          teamPageDisplayColumn
          teamPageDisplayOrder
          title
          textColour
          headshot {
            ext
            url
            formats {
              thumbnail {
                url
              }
              small {
                url
              }
              medium {
                url
              }
              large {
                url
              }
            }
          }
        }
        founders {
          company
          name
          order
          pictureTextColourDesktop
          pictureTextColourMobile
          position
          quote
          tagLineOne
          tagLineTwo
          vimeoVideoId
          picture {
            url
            ext
            formats {
              large {
                url
              }
              thumbnail {
                url
              }
              small {
                url
              }
              medium {
                url
              }
            }
          }
          logo {
            ext
            url
            formats {
              thumbnail {
                url
              }
              small {
                url
              }
              medium {
                url
              }
              large {
                url
              }
            }
          }
        }
        companyInfo {
          description
          orderNumber
          title
        }
        companyProfiles {
          ceo
          description
          founded
          industry
          location
          name
          portfolioTitle
          stage
          url
          websiteUrl
          teamPicture {
            url
            ext
            formats {
              large {
                url
              }
              thumbnail {
                url
              }
              small {
                url
              }
              medium {
                url
              }
            }
          }
          logo {
            url
            ext
            formats {
              thumbnail {
                url
              }
              small {
                url
              }
              medium {
                url
              }
              large {
                url
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
