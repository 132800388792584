import React, { useEffect } from "react"
import styled from "styled-components"

import HoverText from "/src/components/HoverText"

import videoScroll from "/src/images/video-scroll.svg"

const VideoWrapper = styled.div`
  height: calc(100vh - 80px);

  &:before {
    content: "";
    z-index: 1;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.55);
  }

  #video-desktop {
    display: none;
  }

  @media (min-width: 640px) {
    height: calc(100vh - 100px);

    #video-desktop {
      display: block;
    }

    #video-mobile {
      display: none;
    }
  }
`

const scrollToContent = () => {
  const contentElement = document.getElementById("home-portfolio")
  contentElement.scrollIntoView({
    behavior: "smooth",
  })
}
const HomeVideo = ({ data }) => {
  let videoMobileUrl
  let videoDesktopUrl

  try {
    videoMobileUrl = new URL(data.backgroundVideoMobile.url)
    videoDesktopUrl = new URL(data.backgroundVideo.url)
  } catch (error) {
    videoMobileUrl = data.backgroundVideoMobile
      ? `https://${data.backgroundVideoMobile.url}`
      : null
    videoDesktopUrl = data.backgroundVideo
      ? `https://${data.backgroundVideo.url}`
      : null
  }

  return (
    <VideoWrapper className="relative">
      <div className="absolute top-0 left-0 h-full w-full">
        <div className="absolute top-0 w-full h-full bg-mvp-dark-blue">
          <video
            id="video-desktop"
            src={videoDesktopUrl}
            muted
            playsInline
            loop
            autoPlay
            preload="auto"
            className="h-full w-full z-0"
            style={{
              objectFit: "cover",
              objectPosition: "center center",
              background: "#03045E",
            }}
          >
            <source src={videoDesktopUrl} type="video/mp4" />
          </video>

          <video
            id="video-mobile"
            src={videoMobileUrl}
            muted
            playsInline
            loop
            autoPlay
            preload="auto"
            className="h-full w-full z-0"
            style={{
              objectFit: "cover",
              objectPosition: "center center",
              background: "#03045E",
            }}
          >
            <source src={videoMobileUrl} type="video/mp4" />
          </video>
        </div>

        <div className="absolute bottom-32 w-full z-10 text-white">
          <div className="container">
            <h1
              className="relative text-36px leading-tight
        md:text-4xl md:leading-relaxed
        lg:text-5xl lg:leading-relaxed"
            >
              {data.videoLineOneWhite}{" "}
              <span className="text-mvp-light-blue">
                {data.videoLineOneBlue}
              </span>
            </h1>
            <h1
              className="relative text-36px leading-tight pt-6 sm:pt-3 md:pt-0
        md:text-4xl
        lg:text-5xl"
            >
              {data.videoLineTwoWhite}{" "}
              <span className="text-mvp-light-blue">
                {data.videoLineTwoBlue}
              </span>
            </h1>
          </div>
        </div>

        <img
          src={videoScroll}
          className="hidden md:block absolute bottom-10 left-1/2 z-10 cursor-pointer"
          onClick={scrollToContent}
        />
      </div>
    </VideoWrapper>
  )
}

export default HomeVideo
