import React, { useEffect, useState } from "react"
import HomeFounder from "./HomeFounder"

import { sortBy } from "lodash"

const HomeFounders = ({ data, handleVideoButtonClick }) => {
  const [sortedFounders, setSortedFounders] = useState([])

  useEffect(() => {
    setSortedFounders(sortBy(data.founders, ["order"]))
  }, [data])

  return (
    <>
      {sortedFounders.map((founder, index) => {
        const oddEven = index & 1 ? "odd" : "even"
        const mobilePictureTextColour =
          founder.pictureTextColourMobile === "WHITE"
            ? "text-white"
            : "text-mvp-dark-blue"
        const desktopPictureTextColour =
          founder.pictureTextColourDesktop === "WHITE"
            ? "text-white"
            : "text-mvp-dark-blue"
        const logo =
          founder.logo.ext === ".svg"
            ? founder.logo.url
            : founder.logo.formats.small.url

        return (
          <>
            {founder.logo && (
              <HomeFounder
                key={founder.id}
                id={founder.id}
                name={founder.name}
                position={founder.position}
                company={founder.company}
                logo={logo}
                portrait={founder.picture.formats}
                mobilePictureTextColour={mobilePictureTextColour}
                desktopPictureTextColour={desktopPictureTextColour}
                quote={founder.quote}
                tagLineOne={founder.tagLineOne}
                tagLineTwo={founder.tagLineTwo}
                oddEven={oddEven}
                handleVideoButtonClick={() =>
                  handleVideoButtonClick(founder.vimeoVideoId)
                }
              />
            )}
          </>
        )
      })}
    </>
  )
}

export default HomeFounders
