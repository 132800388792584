import React from "react"

const InfoCircle = ({ title, body, id }) => (
  <div key={id} className="max-w-300px md:w-full h-auto inline-block">
    <div
      className="relative w-192px h-192px md:w-full md:h-auto border-2 md:pt-100% border-mvp-dark-blue"
      style={{
        borderRadius: "50%",
      }}
    >
      <div className="absolute top-0 bottom-0 left-0 right-0 px-2 flex flex-col items-center justify-center text-center">
        <div className="pb-2 font-bold tracking-widest uppercase text-16px">
          {title}
        </div>
        {body.map((text) => (
          <div className="md:font-bold md:text-24px leading-tight">{text}</div>
        ))}
      </div>
    </div>
  </div>
)

const HomeCompanyInfo = ({ data }) => {
  return (
    <div className="bg-mvp-light-blue">
      <div className="container py-16">
        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-x-5 gap-y-5 justify-items-center">
          {data.companyInfo &&
            data.companyInfo.map((info) => (
              <InfoCircle
                title={info.title}
                body={info.description}
                key={info.id}
                id={info.id}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

export default HomeCompanyInfo
