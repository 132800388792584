import React, { useState } from "react"
import styled from "styled-components"
import { ExternalLink } from "../../Button"

const CardWrapper = styled.div`
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      #card-wrapper-hover {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
`

const CardHover = styled.div`
  opacity: ${(props) => (props.isHoverCardVisible ? 1 : 0)};
  transform: translateX(${(props) => (props.isHoverCardVisible ? 0 : "-100%")});
  transition: all 0.2s ease-in-out;
`

const ImageWrapper = styled.div`
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    //background-color: rgba(3, 4, 94, 0.3);
    //background-blend-mode: screen;
  }
`

const CardNameTitle = ({ name, title, textColour, className }) => (
  <div className={`text-left ${textColour} ${className}`}>
    <div className="font-bold tracking-wide uppercase">{name}</div>
    <div className="tracking-wide leading-none">{title}</div>
  </div>
)

const TeamMemberInfo = ({ description, linkedInId }) => (
  <div
    className="flex flex-col w-full h-full text-white
  "
  >
    <div className="flex flex-grow justify-center items-center mx-4 sm:mx-8 tracking-wide">
      {description}
    </div>

    <div className="flex h-20 bg-mvp-dark-blue">
      <div className="flex mx-8 h-full items-center">
        <ExternalLink label="Linkedin" url={`//linkedin.com/${linkedInId}`} />
      </div>
    </div>
  </div>
)

export const TeamCard = ({
  name,
  title,
  bio,
  linkedInId,
  images,
  textColour,
  className,
  isUserScrolling,
}) => {
  let cardTextColour = "text-white"
  const [isHoverCardVisible, setIsHoverCardVisible] = useState(false)

  if (textColour) {
    switch (textColour) {
      case "WHITE":
        cardTextColour = "text-white"
        break
      case "BLACK":
        cardTextColour = "text-black"
        break
      case "DARK_BLUE":
        cardTextColour = "mvp-text-dark-blue"
        break
    }
  }

  return (
    <CardWrapper
      className={`flex justify-center h-450px sm:h-500px md:h-400px xl:h-500px items-end ${className}`}
      onTouchEnd={() => {
        if (!isUserScrolling) {
          setIsHoverCardVisible(!isHoverCardVisible)
        }
      }}
    >
      <div
        style={{}}
        className={`flex relative w-full mx-auto overflow-hidden text-center`}
      >
        <span className="relative h-full inline-block mx-auto">
          <ImageWrapper>
            <img src={images.small.url} className="mx-auto" />
          </ImageWrapper>

          <CardNameTitle
            name={name}
            title={title}
            textColour={cardTextColour}
            className="absolute bottom-4 left-4"
          />
        </span>

        <CardHover
          id="card-wrapper-hover"
          className="absolute top-0 w-full h-full bg-mvp-dark-blue-70"
          isHoverCardVisible={isHoverCardVisible}
        >
          <TeamMemberInfo description={bio} linkedInId={linkedInId} />
        </CardHover>
      </div>
    </CardWrapper>
  )
}
