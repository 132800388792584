import React from "react"

import Divider from "../Divider"
import VideoButton from "../VideoButton"

const HomeFounder = ({
  id,
  name,
  position,
  company,
  logo,
  portrait,
  desktopPictureTextColour,
  mobilePictureTextColour,
  quote,
  tagLineOne,
  tagLineTwo,
  oddEven,
  handleVideoButtonClick,
}) => {
  const isOdd = oddEven === "odd"

  const oddClasses = {
    wrapper: "bg-mvp-light-blue",
    oneThirdBackground: "right-0",
    oneThirdText: "right-5 text-right",
    twoThirds: "text-mvp-dark-blue",
    dividerColour: "dark",
  }

  const evenClasses = {
    wrapper: "bg-mvp-dark-blue",
    oneThirdBackground: "left-0",
    oneThirdText: "left-5",
    twoThirds: "text-mvp-light-blue",
    dividerColour: "light",
  }

  const classes = isOdd ? oddClasses : evenClasses

  return (
    <div
      key={id}
      className={`relative overflow-hidden
        md:h-screen md:max-h-650px
        lg:max-h-screen
        xl:max-h-screen
        ${classes.wrapper}`}
    >
      <div
        className={`hidden absolute top-0 bottom-0 ${classes.oneThirdBackground} w-1/3 bg-cover bg-no-repeat bg-center
        md:block
    `}
        style={{
          backgroundImage: `url( ${portrait.large.url} )`,
        }}
      >
        <div
          className={`absolute bottom-5 ${classes.oneThirdText} z-20 ${desktopPictureTextColour}`}
        >
          <div>{name}</div>
          <div>{`${position}, ${company}`}</div>
        </div>
      </div>

      <div
        className="container max-h-65vh 
        md:h-screen md:max-h-650px 
        lg:max-h-screen"
      >
        <div
          className="flex max-h-65vh items-center 
          md:h-screen md:max-h-650px
          lg:max-h-screen"
        >
          {!isOdd && (
            <div
              className="hidden
            md:block md:w-4/12
          "
            ></div>
          )}

          <div
            className={`flex flex-col items-center py-16 md:py-0
                ${classes.twoThirds}
                md:w-8/12 md:h-screen md:max-h-650px md:justify-evenly
              lg:max-h-screen"
            `}
          >
            <img
              src={logo}
              className="pb-4 max-w-200px 
            md:max-w-250px md:pb-0
            lg:max-w-max"
            />
            <h1
              className="text-36px font-light tracking-tight leading-tight text-center py-8
                md:py-0
                lg:text-5xl lg:leading-normal"
            >
              {quote}
            </h1>

            <Divider
              colour={classes.dividerColour}
              className="hidden w-16 mb-6
                md:block md:mb-0
                lg:w-20
              "
            />

            <div className="flex flex-col items-center">
              {tagLineOne && (
                <h2
                  className="text-center tracking-wide
                lg:text-xl
                xl:text-2xl"
                >
                  {tagLineOne}
                </h2>
              )}

              {tagLineTwo && (
                <h2
                  className="text-center tracking-wide leading-loose
                lg:text-xl lg:leading-loose
                xl:text-2xl xl:leading-loose"
                >
                  {tagLineTwo}
                </h2>
              )}
            </div>

            <div
              className="pt-12
                md:pb-0 md:pt-0
            "
            >
              <VideoButton
                colour={isOdd ? "dark" : "light"}
                handleVideoButtonClick={handleVideoButtonClick}
              />
            </div>
          </div>

          {isOdd && (
            <div
              className="hidden
            md:block md:w-4/12
          "
            ></div>
          )}
        </div>
      </div>

      <div
        className="relative overflow-hidden h-50vh
      md:hidden
      "
      >
        <div
          className="bg-no-repeat bg-cover bg-home-founder-portrait h-full"
          style={{
            backgroundImage: `url( ${portrait.large.url} )`,
          }}
        ></div>

        <div
          className="absolute top-0 bottom-0 left-0 right-0 z-10"
          style={{
            background: "rgb(255,255,255)",
            background:
              "linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.5) 100%)",
          }}
        ></div>

        <div
          className={`absolute bottom-4 left-4 z-20 ${mobilePictureTextColour}`}
        >
          <div>{name}</div>
          <div>{`${position}, ${company}`}</div>
        </div>
      </div>
    </div>
  )
}

export default HomeFounder
