import { sortBy } from "lodash"
import React, { useEffect, useState } from "react"
import Button from "../../Button"
import { TeamCard } from "./TeamCard"

const Introduction = ({ data }) => (
  <>
    <div
      className="max-w-xl font-light text-36px leading-tight
       lg:max-w-3xl lg:text-4-5xl
    "
    >
      {data.teamTitle}
    </div>

    <div
      className="max-w-lg pt-4 pb-8
      lg:max-w-2xl lg:text-24px
    "
    >
      {data.teamDescription}
    </div>

    <div className="flex flex-col sm:flex-row items-center">
      <Button
        page="/team/investment"
        label={data.teamButtonLabel}
        className="w-2/3 text-center mb-4 sm:w-auto sm:mb-0 sm:mr-4"
      />
    </div>
  </>
)

const HomeTeam = ({ data }) => {
  const [sortedTeamMembers, setSortedTeamMembers] = useState(null)
  const [isUserScrolling, setIsUserScrolling] = useState(false)
  const handleOnTouchMove = () => setIsUserScrolling(true)
  const handleOnTouchEnd = () => setIsUserScrolling(false)

  useEffect(() => {
    setSortedTeamMembers(sortBy(data.teamMembers, "homePageDisplayOrder"))
  }, [])

  return (
    <>
      {data && (
        <div
          className="bg-white"
          onTouchMove={handleOnTouchMove}
          onTouchEnd={handleOnTouchEnd}
        >
          <div
            className="container py-16
              lg:py-32
          "
          >
            <Introduction data={data} />

            <div
              className="grid gap-6 pt-10
            sm:grid-cols-1
            md:grid-cols-2 
            lg:gap-10 lg:pt-32
            2xl:grid-cols-3
            "
            >
              {sortedTeamMembers &&
                sortedTeamMembers.map((member) => {
                  return (
                    <TeamCard
                      name={member.name}
                      title={member.title}
                      bio={member.bio}
                      linkedInId={member.linkedInId}
                      images={member.headshot.formats}
                      textColour={member.textColour}
                      isUserScrolling={isUserScrolling}
                    />
                  )
                })}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export { TeamCard }

export default HomeTeam
