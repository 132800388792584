import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import { sortBy, orderBy } from "lodash"
import { filter } from "lodash"

import { graphql, Link } from "gatsby"
import { MetaTagsContext } from "../layouts"

const CompanyWrapper = styled.div`
  div:nth-child(6) {
    margin-left: 170px;
  }
  div:nth-child(15) {
    margin-left: 170px;
  }

  div:nth-child(9) {
    margin-right: 170px;
  }
  div:nth-child(18) {
    margin-right: 170px;
  }

  div:nth-child(10) {
    margin-left: 0px;
  }
  div:nth-child(19) {
    margin-left: 0px;
  }

  div ~ div {
    margin-left: 60px;
  }
`

const CompanyCardHover = styled.div`
  position: relative;

  &:hover {
    &::before {
      content: "";
      border-radius: 2rem;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      background: url(${(props) => props.teamPhoto});
      background-position: center;
      filter: grayscale(100%);
    }

    &::after {
      content: "";
      border-radius: 2rem;
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      background: rgba(3, 4, 94, 0.8);
    }

    img {
      filter: brightness(0) invert(1);
    }
  }
`

const CompanyLogo = styled.img`
  z-index: 1;
`

const CompanyTile = ({ logo, teamPhoto, className, link }) => (
  <Link to={`/portfolio/${link}`}>
    <CompanyCardHover teamPhoto={teamPhoto} className="cursor-pointer">
      <div
        className={`flex bg-white border border-white rounded-4xl shadow-xl justify-center items-center px-4
          transition transition-colors duration-500 ease-in-out ${className}`}
      >
        <CompanyLogo src={logo} className="w-165px" />
      </div>
    </CompanyCardHover>
  </Link>
)

const Companies = ({ data, filterId, isExit }) => {
  let companyData
  const isAllFilter = filterId === "ALL"

  companyData = isExit ? data.filter((company) => company.hasExited) : data

  return (
    <CompanyWrapper className="flex flex-wrap justify-evenly py-8 md:py-32">
      {companyData.map((company) => {
        const hasLogo = !!company.logo
        const hasTeamPicture = !!company.teamPicture
        const logo = company.logo
          ? company.logo.formats && company.logo.formats.small
            ? company.logo.formats.small.url
            : company.logo.url
          : null
        const teamPicture = hasTeamPicture
          ? company.teamPicture.formats && company.teamPicture.formats.small
            ? company.teamPicture.formats.small.url
            : company.teamPicture.url
          : null

        if (hasLogo && (isAllFilter || filterId === company.industry)) {
          return (
            <CompanyTile
              logo={logo}
              teamPhoto={teamPicture}
              link={company.url}
              className="w-165px h-165px my-9
                  md:w-192px md:h-192px"
            />
          )
        }
      })}
    </CompanyWrapper>
  )
}

const Toggle = styled.div`
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 9999px;
    background-color: #fff;
    border: 1px solid #B9B9B9;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: "";
      top: 4px;
      left: 4px;
      padding: 18.5%;
      background-color: #B9B9B9;
      border-radius: 50%;
      transition: 0.4s;
    }

    &:after {
      content: "EXIT";
      z-index: 1;
      position: absolute;
      top: 50%;
      right 6px;
      transform: translateY(-50%);
      color: #B9B9B9;
    }
  }

  input:checked + .slider {
      background-color: #03045e;
    
      &:before {
      background-color: #fff;
    }

    &:after {
      color: #03045e;
    }
  }

  input:checked + .slider:before {
    transform: translateX(calc(100% + 20px));
  }

  :hover {
    .slider {
      border-color: #03045E;

      &:before {
        background-color: #03045E;
      }

      &:after {
        color: #03045E;
      }
    }
  }
`

const ToggleElement = ({ className, id, checked, onChange }) => (
  <Toggle
    className={`mt-4 lg:mr-4 text-15px tracking-widest cursor-pointer ${className}`}
  >
    <label for={id} className="relative inline-block py-3 px-12">
      <input type="checkbox" id={id} checked={checked} onChange={onChange} />
      <span class="slider round"></span>
    </label>
  </Toggle>
)

const Filters = ({ filterId, handleClick, data, handleExit, isExit }) => {
  return (
    <div id="portfolio-filters">
      <ToggleElement
        className="md:hidden"
        id="exit-mobile"
        checked={isExit}
        onChange={handleExit}
      />
      <div
        className="flex flex-wrap items-center justify-start
        md:justify-between
        lg:justify-start
  "
      >
        <ToggleElement
          className="hidden md:block"
          id="exit-desktop"
          checked={isExit}
          onChange={handleExit}
        />
        <FilterPill
          filterId={filterId}
          id="ALL"
          label={data.filterButtonAll}
          handleClick={handleClick}
          selectedFilterId
        />
        <FilterPill
          filterId={filterId}
          id="ENTERPRISE"
          label={data.filterButtonEnterprise}
          handleClick={handleClick}
          selectedFilterId
        />
        <FilterPill
          filterId={filterId}
          id="MARKETPLACES"
          label={data.filterButtonMarketplaces}
          handleClick={handleClick}
          selectedFilterId
        />
        <FilterPill
          filterId={filterId}
          id="B2B"
          label={data.filterButtonSaas}
          handleClick={handleClick}
          selectedFilterId
        />
      </div>
    </div>
  )
}

const FilterPill = ({ id, filterId, label, handleClick }) => {
  const isPillActive = id === filterId
  const activeClasses = "bg-mvp-dark-blue text-white border-mvp-dark-blue"
  const inactiveClasses = "bg-white text-mvp-med-grey border-mvp-light-grey"

  return (
    <div
      className={`border rounded-full mr-2
        md:mr-0
        mt-4 lg:mr-4
        py-3 px-6 
        text-15px tracking-widest 
        hover:bg-mvp-dark-blue hover:text-white hover:border-mvp-dark-blue
        cursor-pointer
        transition transition-colors duration-500 ease-in-out
        ${isPillActive ? activeClasses : inactiveClasses}`}
      onClick={() => handleClick(id)}
    >
      {label}
    </div>
  )
}

const Portfolio = ({ data, location }) => {
  const { setMetaTags } = useContext(MetaTagsContext)

  const [filterId, setFilterId] = useState("ALL")
  const [foundersColumnOne, setFoundersColumnOne] = useState([])
  const [foundersColumnTwo, setFoundersColumnTwo] = useState([])
  const [foundersColumnThree, setFoundersColumnThree] = useState([])
  const [foundersColumnFour, setFoundersColumnFour] = useState([])
  const [sortedPortfolioCompanies, setSortedPortfolioCompanies] = useState([])
  const [isExit, setIsExit] = useState(false)

  const portfolioData = data.strapiPortfolio.data

  setMetaTags(data.strapiPortfolio.data.seo)

  useEffect(() => {
    const columnOne = filter(
      portfolioData.founders,
      (founder) => founder.column === "ONE"
    )
    const columnTwo = filter(
      portfolioData.founders,
      (founder) => founder.column === "TWO"
    )
    const columnThree = filter(
      portfolioData.founders,
      (founder) => founder.column === "THREE"
    )
    const columnFour = filter(
      portfolioData.founders,
      (founder) => founder.column === "FOUR"
    )

    setFoundersColumnOne(sortBy(columnOne, ["orderNumber"]))
    setFoundersColumnTwo(sortBy(columnTwo, ["orderNumber"]))
    setFoundersColumnThree(sortBy(columnThree, ["orderNumber"]))
    setFoundersColumnFour(sortBy(columnFour, ["orderNumber"]))
    setSortedPortfolioCompanies(
      orderBy(portfolioData.companyProfiles, ["name"], ["asc"])
    )
  }, [portfolioData])

  const handleHashChange = () => {
    const filterHash = window.location.search.substring(1)
    const filterKey = filterHash.toUpperCase()
    const isFilterValid =
      filterKey === "ALL" ||
      filterKey === "ENTERPRISE" ||
      filterKey === "MARKETPLACES" ||
      filterKey === "B2B"

    if (isFilterValid) {
      setFilterId(filterKey)
    }
  }

  useEffect(() => {
    handleHashChange()
  }, [])

  useEffect(() => {
    handleHashChange()
  }, [location])

  const setFilter = (id) => {
    setFilterId(id)
  }

  const handleExit = () => {
    setIsExit(!isExit)
  }

  const FounderWrapper = styled.div`
    position: relative;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      width: 100%;
      background: rgba(3, 4, 94, 0.35);
    }
  `

  return (
    <div className="bg-white py-10 lg:py-16">
      <div className="container">
        <div
          className="flex flex-col justify-between
          md:flex-row md:items-center
        "
        >
          <div
            className="font-light text-36px leading-snug flex-shrink
             md:w-1/2 md:pr-6
            xl:max-w-39rem xl:text-4-5xl xl:pr-10
          "
          >
            {portfolioData.description}
          </div>
          <div
            className="flex flex-grow
            md:w-1/2
          "
          >
            <div
              className="grid grid-cols-4 gap-2 py-10 w-full
          "
            >
              <div className="grid grid-cols-1 self-start gap-2">
                {foundersColumnOne.map((founder) => {
                  const image = !!founder.image.formats.small
                    ? founder.image.formats.small.url
                    : founder.image.url

                  return (
                    <FounderWrapper key={founder.id}>
                      <div
                        className="bg-cover bg-no-repeat bg-top h-154px max-w-full"
                        style={{
                          backgroundImage: `url( ${image} )`,
                          filter: "grayscale(100%)",
                        }}
                      />
                    </FounderWrapper>
                  )
                })}
              </div>
              <div className="grid grid-cols-1 self-start gap-2 pt-8">
                {foundersColumnTwo.map((founder) => (
                  <FounderWrapper key={founder.id}>
                    <div
                      className="bg-cover bg-no-repeat bg-top h-154px max-w-full"
                      style={{
                        backgroundImage: `url( ${founder.image.url} )`,
                        filter: "grayscale(100%)",
                      }}
                    />
                  </FounderWrapper>
                ))}
              </div>
              <div className="grid grid-cols-1 self-start gap-2">
                {foundersColumnThree.map((founder) => {
                  const image = !!founder.image.formats.small
                    ? founder.image.formats.small.url
                    : founder.image.url
                  return (
                    <FounderWrapper key={founder.id}>
                      <div
                        className="bg-cover bg-no-repeat bg-top h-154px max-w-full"
                        style={{
                          backgroundImage: `url( ${image} )`,
                          filter: "grayscale(100%)",
                        }}
                      />
                    </FounderWrapper>
                  )
                })}
              </div>
              <div className="grid grid-cols-1 self-start gap-2 pt-8">
                {foundersColumnFour.map((founder) => (
                  <FounderWrapper key={founder.id}>
                    <div
                      className="bg-cover bg-no-repeat bg-top h-154px max-w-full"
                      style={{
                        backgroundImage: `url( ${founder.image.url} )`,
                        filter: "grayscale(100%)",
                      }}
                    />
                  </FounderWrapper>
                ))}
              </div>
            </div>
          </div>
        </div>

        <Filters
          filterId={filterId}
          handleClick={setFilter}
          handleExit={handleExit}
          isExit={isExit}
          data={portfolioData}
        />

        {portfolioData && portfolioData.companyProfiles && (
          <Companies
            data={sortedPortfolioCompanies}
            filterId={filterId}
            isExit={isExit}
          />
        )}
      </div>
    </div>
  )
}

export const query = graphql`
  {
    strapiPortfolio {
      data {
        description
        filterButtonAll
        filterButtonEnterprise
        filterButtonMarketplaces
        filterButtonSaas
        seo {
          title
          description
        }
        founders {
          column
          orderNumber
          image {
            url
            ext
            formats {
              thumbnail {
                url
              }
              small {
                url
              }
              medium {
                url
              }
              large {
                url
              }
            }
          }
        }
        companyProfiles {
          ceo
          founded
          industry
          location
          name
          portfolioTitle
          stage
          url
          websiteUrl
          hasExited
          logo {
            url
            ext
            formats {
              thumbnail {
                url
              }
              small {
                url
              }
              medium {
                url
              }
              large {
                url
              }
            }
          }
          teamPicture {
            ext
            url
            formats {
              thumbnail {
                url
              }
              small {
                url
              }
              medium {
                url
              }
              large {
                url
              }
            }
          }
        }
      }
    }
  }
`

export { CompanyTile }
export default Portfolio
