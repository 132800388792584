import React from "react"

const VideoButton = ({
  label = "Watch Video",
  colour,
  handleVideoButtonClick,
}) => {
  const lightBlueColours = {
    border: "border-mvp-light-blue",
    text: "text-mvp-light-blue",
    hoverBackground: "hover:bg-mvp-light-blue",
    hoverText: "hover:text-mvp-dark-blue",
    hoverBorder: "hover:border-mvp-dark-blue",
  }

  const darkBlueColours = {
    border: "border-mvp-dark-blue",
    text: "text-mvp-dark-blue",
    hoverBackground: "hover:bg-mvp-dark-blue",
    hoverText: "hover:text-mvp-light-blue",
    hoverBorder: "hover:border-mvp-light-blue",
  }

  const colours = colour === "dark" ? darkBlueColours : lightBlueColours

  return (
    <button
      onClick={handleVideoButtonClick}
      className={`flex items-center border ${colours.border} rounded-full cursor:pointer ${colours.text}
         ${colours.hoverBackground} ${colours.hoverBorder} ${colours.hoverText}
        transition transition-colors duration-300 ease
    `}
    >
      <div
        className={`text-14px uppercase tracking-widest border-r py-3 px-7 ${colours.border} ${colours.hoverBorder}`}
      >
        {label}
      </div>

      <div className="px-5">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="11"
          height="14"
          viewBox="0 0 11 14"
        >
          <path
            id="ic_play_arrow_24px"
            d="M8,5V19l11-7Z"
            transform="translate(-8 -5)"
            fill="currentColor"
          />
        </svg>
      </div>
    </button>
  )
}

export default VideoButton
