import React from "react"
import styled from "styled-components"
import Button from "../Button"

const BackgroundImage = styled.div`
  @media (min-width: 1024px) {
    background: url(${(props) => props.image});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
`

const HomeCodeOfConduct = ({ data }) => {
  const backgroundImage = data.conductBackground.url
  const illustrationImage = data.conductIllustration.url

  return (
    <div className="bg-mvp-dark-blue text-mvp-light-blue py-16 xl:py-44">
      <div className="container">
        <div className="flex flex-col lg:flex-row items-center">
          {/* 10 Dont's */}
          {backgroundImage && illustrationImage && (
            <BackgroundImage
              image={backgroundImage}
              className="flex order-2 items-center justify-center 
            lg:order-1 lg:py-48 lg:flex-grow"
            >
              <img src={illustrationImage} />
              <div className="max-w-210px ml-4 font-light text-2-5xl leading-none">
                {data.conduct10Donts}
              </div>
            </BackgroundImage>
          )}

          {/* Code of Conduct */}
          <div
            className="flex flex-col order-1 pb-8
            md:pb-12
            lg:order-2 lg:pl-8
            xl:pl-0
        "
          >
            <div
              className="pb-4 font-light text-36px
            md:text-4xl
            xl:text-56px
          "
            >
              {data.conductTitle}
            </div>
            <div
              className="max-w-sm pb-6 
          xl:pb-8 xl:max-w-lg xl:text-24px xl:pt-6"
            >
              {data.conductQuote}
            </div>

            <Button
              page="/code-of-conduct"
              colour="light"
              label={data.conductButtonLabel}
              className="max-w-175px text-center"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeCodeOfConduct
