import React from "react"
import Button from "../Button"

const HomePodcastShow = ({ data }) => (
  <div className="bg-white">
    <div
      className="container py-16
      xl:py-28
    "
    >
      <div
        className="flex flex-col items-center justify-between
        lg:flex-row
      "
      >
        <div
          className="font-light text-36px leading-none 
        lg:w-1/2 lg:text-4xl lg:pr-20
        xl:text-5xl xl:pr-20
        2xl:text-80px xl:leading-tight
        "
        >
          {data.pmfShowTitle}
        </div>
        <div
          className="flex flex-col items-center
            lg:block lg:w-1/2
            xl:text-24px
        "
        >
          <div className="py-6">{data.pmfShowDescription}</div>

          <Button
            label={data.pmfShowButtonLabel}
            page="/the-product-market-fit-show"
          />
        </div>
      </div>
    </div>
  </div>
)

export default HomePodcastShow
